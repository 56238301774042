<template>
  <v-container fluid class="pa-0">
    <v-sheet color="transparent" elevation="0">
      <v-carousel cycle height="40vh">
        <v-carousel-item v-for="(item, i) in contents['Carousel']" :key="i">
          <v-sheet :color="item.color" height="100%" tile>
            <v-layout fill-height align-center justify-center class="banner">
              <v-flex xs12 sm12 md8>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                  @click="handleClick(item)"
                >
                  <v-col cols="6" md="6" v-if="!isXs">
                    <v-img
                      contain
                      :src="getImagePhoto(item.account, item.picture)"
                    ></v-img
                  ></v-col>
                  <v-col cols="6" md="6">
                    <div class="text-h3 white--text">
                      <p class="text-md-h4 text-h3 font-weight-bold">
                        {{ item.title }}
                      </p>
                      <p class="text-md-h5 text-h6">{{ item.subtitle }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-timeline v-if="!isXs">
        <v-container>
          <v-btn fab dark small absolute top right color="success" @click="newForm">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-timeline-item v-for="(itm, i) in contents['Body']" :key="i">
            <template v-slot:opposite>
              <v-avatar color="white" size="200">
                <v-img contain :src="getImagePhoto(itm.account, itm.picture)"></v-img>
              </v-avatar>
            </template>
            <div class="py-4" @click="handleClick(itm)">
              <span :class="`headline font-weight-bold `" v-text="itm.title"></span>
              <h2 :class="`headline font-weight-light mb-4 `" v-html="itm.subtitle"></h2>
            </div>
          </v-timeline-item>
        </v-container>
      </v-timeline>

      <v-row v-else>
        <v-col
          class="col-12 col-sm-6 col-md-4"
          md="3"
          v-for="content in contents['Body']"
          :key="content.code"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card height="100%" class="card-outter">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-height="300"
                  :src="getImagePhoto(content.account, content.picture)"
                ></v-img>
                <v-card-title class="pa-2 v-card-title"
                  >{{ content.title }}
                </v-card-title>
                <v-card-subtitle>
                  {{ content.subtitle }}
                </v-card-subtitle>
                <v-card-text class="text-justify" v-html="content.content"> </v-card-text>
                <v-card-actions class="card-actions">
                  <v-spacer></v-spacer>
                  <v-btn v-if="content.link" color="deep-purple lighten-2" text>
                    {{ content.link }}
                  </v-btn>
                </v-card-actions>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-btn @click="handleClick(content)">Edit</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-footer dark>
        <v-card
          flat
          tile
          width="100%"
          color="transparent"
          class="white--text text-center"
        >
          <v-card-text class="white--text">
            <v-row align="center" justify="center">
              <v-col cols="auto">
                {{ this.company.name }}<br />
                {{ this.company.email }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-btn
              class="text-capitalize font-weight-light white--text"
              @click="handleClick(itm)"
              text
              v-for="(itm, i) in contents['Footer']"
              :key="i"
            >
              {{ itm.title }}
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text text-capitalize font-weight-light">
            {{ new Date().getFullYear() }} — {{ this.company.name }}
          </v-card-text>
        </v-card>
      </v-footer>

      <ContentForm
        table="contents"
        :item="item"
        :dialog="dialog"
        :newItem="newItem"
        @close="dialog = false"
        @refresh="refresh()"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createContent.js";
import ContentForm from "../../components/ContentForm.vue";
import createParty from "../../utils/createParty.js";
export default {
  components: { ContentForm },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.onResize();
    this.get_items();
  },
  data() {
    return {
      dialog: false,
      newItem: false,
      search: "",
      company: createParty(),
      contents: { Carousel: [], Body: [], Footer: [] },
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      isXs: false,
      headers: {
        false: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Categoria",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
          },
          {
            text: "Titulo",
            align: "start",
            sortable: true,
            value: "title",
            dataType: "text",
          },
          {
            text: "Subtitulo",
            align: "start",
            sortable: true,
            value: "subtitle",
            dataType: "text",
          },
          {
            text: "Contenido",
            align: "start",
            sortable: true,
            value: "content",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Titulo",
            align: "start",
            sortable: true,
            value: "title",
            dataType: "text",
          },
        ],
      },
    };
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = e;
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "contents",
        filters: [],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table", qry, (data) => {
        this.$store.dispatch("setloading", false);
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));
        this.contents = ctgry;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url = "https://mayorista.app/images/" + account + "/" + src;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 64px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
