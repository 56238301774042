export default (data = {}) => {
    return {
        account: null,
        code: null,
        category: "Category",
        title: "Title",
        subtitle: "Subtitle",
        content: "Content",
        picture: null,
        active: null,
        color: null,
        ...data
    }
}