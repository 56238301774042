<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card>
          <v-card-title>
            Contenido
            <v-spacer></v-spacer>
            <v-icon @click="close()"> mdi-close </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" align="center" justify="center">
                <v-img
                  contain
                  aspect-ratio="1"
                  class="grey lighten-5"
                  :src="productPhoto"
                  max-height="250"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <v-file-input
                  class="file-input"
                  ref="fileInput"
                  v-model="chosenPhoto"
                  @change="PhotoToBase64"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12" md="8">
                <v-select
                  v-model="item.category"
                  :items="categories"
                  label="Type"
                  outlined
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.color"
                  type="color"
                  label="Color"
                  filled
                  background-color="item.color"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.title"
                  :rules="f_required"
                  label="Título"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.subtitle"
                  label="Sub-título"
                  hide-details="auto"
                  :rules="f_required"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="vueEditorCSS">
                <vue-editor
                  v-model="item.content"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-btn @click="delete_itm(item)" :disabled="newItem">
                <span>Borrar</span>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>

              <v-btn :disabled="!valid" type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createContent.js";
import { VueEditor } from "vue2-editor";
export default {
  name: "PartyForm",
  components: { VueEditor },
  data() {
    return {
      edit: false,
      chosenPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      categories: ["Carousel", "Body", "Footer"],
      productPhoto: null,
      recenter: false,
      ipgeo: null,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        // [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        //[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],

      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      stloc: null,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    groups: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    // var dta = this.cities;
    // console.log(dta);
    // const keys = Object.keys(dta).sort();
    // this.countries = keys;
    // var stt = this.cities[this.countries[0]];
    // this.state = Object.keys(stt).sort();
    // this.citi=this.cities[this.countries[0]][this.state[0]].sort();
    // this.edit = false;
  },
  methods: {
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    re_center() {
      this.recenter = !this.recenter;
    },
    acceptNumber(e) {
      console.log(e);
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, code, src) {
      var url = "https://mayorista.app/images/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.$refs.input.click();
    },
    PhotoToBase64(e) {
      console.log(e);
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      } else {
        this.item.picture = this.chosenPhoto.name;
      }

      this.getBase64(this.chosenPhoto).then((data) => {
        this.compressed_image = data;
        this.productPhoto = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.item.account = this.$store.state.profile.account;
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.image = this.compressed_image;
        }
        console.log(qry);
        webserver("put_table", qry, (data) => {
          console.log(data);
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", false);
    },

    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
    onLoaded(e) {
      console.log(e);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
  },
  watch: {
    item: function (e) {
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, e.code, e.picture);
      } else {
        this.productPhoto = null;
      }
      if (e.menus) {
        this.menuPhoto = this.getImageMenu(e.account, e.code, e.menus);
      } else {
        this.menuPhoto = null;
      }
      if (this.newItem) {
        this.edit = true;
      }
    },
  },
};
</script>

<style>
.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 100px;
}
</style>
